.techTool__iconBox {
    width: 6.6rem;
    height: 6.8rem;
    padding: 1.24rem;
    background-color: $secondary-whitesmoke-color;
    border-radius: 10%;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.125);
    display: grid;
    place-items: center;
    transform: 5s box-shadow 0s ease;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.125);
    }

    img.techTool__icon {
        width: 100%;
    }
}

@media only screen and (max-width: $bp-allPhones) {
    .techTool__iconBox {
        width: 5.2rem;
        height: 5rem;
        background-color: transparent;
        box-shadow: none;
        padding: 0;

        img.techTool__icon {
            width: 68%;
            height: auto;
        }
    }
}

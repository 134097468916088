@import "./projectSubDescriptionCard";

.projectCard {
    gap: 0 5rem; // column gap

    &:not(:last-child) {
        margin-bottom: 19rem;
    }

    &:nth-child(4) {
        .projectCard__imgBox {
            * {
                border: 1px solid #e5e5e5;
            }
        }
    }

    .projectCard__description {
        margin-bottom: 3rem;
        width: 98%;
    }
    .projectCard__linkBtn {
        font-weight: $fw-medium;
        font-size: 1.8rem;
        line-height: 1.63em;
        border-radius: 5px;
        padding: 0.3888889em 1.555556em;
    }
    .projectCard__imgBox {
        // ---- Breakpoint | above mobile screens ------
        @media only screen and (min-width: 768.1px) {
            position: relative;

            .projectCard__img {
                position: absolute;
                transition: 500ms opacity 0s ease;
            }
            .projectCard__imgOne {
                top: 5.7rem;
                width: 43.2rem;
                height: 21.4rem;
                z-index: 2;
            }
            .projectCard__imgTwo {
                top: 20.4rem;
                width: 43.9rem;
                height: 21.4rem;
                right: 0;
                opacity: 0.2;
            }
        }
    }
}

// ---- Breakpoint : above mobile screens ------
@media only screen and (min-width: 768.1px) {
    .projectCard__mobileProjectHeading {
        display: none;
    }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    .projectCard {
        margin-bottom: 18rem;

        .projectCard__heading {
            display: none;
        }

        .projectCard__mobileProjectHeading,
        .projectCard__imgBox {
            margin-bottom: 1.6rem;
        }

        .projectCard__mobileProjectHeading {
            font-size: 2.2rem;
            line-height: 1.5em;
        }

        .projectCard__imgBox {
            .projectCard__imgOne {
                max-width: 100%;
                height: auto;
            }
            .projectCard__imgTwo {
                display: none;
            }
        }

        .projectCard__description {
            margin-bottom: 3.2rem;
        }

        .projectCard__linkBtn {
            font-size: 1.6rem;
            line-height: 1.3125em;
            padding: 0.75em 1.125em;
        }
    }
}

#footer {
  background-color: $secondary-whitesmoke-color;
  background-color: #ededed;
  color: $light-text-color;
  padding: 1.6rem 0;

  .footer__flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer__txt {
      color: $light-text-color;
      font-size: 1.8rem;
      line-height: 1.63em;
      font-weight: $fw-regular;
      margin-right: 0.4444444em;

      a.footer__link:any-link {
        color: $primary-darkPink-color;
        font-weight: $fw-medium;
      }

      a:hover {
        text-decoration: underline;
      }
    }
    .footer__figmaIcon {
      width: 1.8rem;
      height: 2.8rem;
    }
  }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
  #footer {
    padding: 1.25rem;
    .footer__flex-wrapper {
      .footer__txt {
        font-size: 1.4rem;
        line-height: 1.63em;
      }
    }
  }
}

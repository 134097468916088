#about {
    background-color: $secondary-whitesmoke-color;
    padding: 6.4rem 0;

    .aboutSec__mainHeading {
        margin-bottom: 1.09677em;
    }

    .aboutSec__imgBox {
        @include flex-center();

        .aboutSec__img {
            width: 40.9rem;
            height: 40.2rem;
        }
    }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    #about {
        padding: 8rem 0;
        $equal-mb-items: 3.2rem;

        .aboutSec__mainHeading {
            margin-bottom: $equal-mb-items;
        }
        .aboutSec__imgBox {
            max-width: 100%;
            margin-bottom: $equal-mb-items;

            .aboutSec__img {
                width: 19.153rem;
                height: auto;
            }
        }
    }
}

#contactInfo {
  background-color: $primary-darkPink-color;
  padding: 4.8rem 2.4rem 9.9rem;
  border-radius: 11px;

  .contactInfo__mainHeading {
    color: $white-color;
    font-size: 2.6rem;
    font-weight: $fw-medium;
    line-height: 1.63em;
    margin-bottom: 0.2738461em;
  }

  .contactInfo__subHeading {
    color: $white-color;
    font-weight: $fw-regular;
    font-size: 1.4rem;
    line-height: 1.63em;
    max-width: 45ch;
    margin-bottom: 3.2142857em;
  }

  .contactInfo__socialIconsList {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 2.8rem 0;

    a:any-link {
      color: $white-color;
    }

    a:hover {
      color: #eeeeee;
      text-decoration: underline;
      text-decoration-color: $white-color;
    }

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row nowrap;

      & a {
        margin-left: 1.25em;
        font-size: 1.6rem;
        line-height: 1.63em;
        font-weight: $fw-medium;
      }
    }
  }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
  #contactInfo {
    padding: 4.4rem 2rem 2.6rem;
    width: 100%;
    overflow-x: hidden;
    border-radius: 19px;

    .contactInfo__mainHeading {
      font-size: 2.2rem;
      line-height: 1.63em;
      font-weight: $fw-semi-bold;
      margin-bottom: 0.3636363em;
    }
    .contactInfo__subHeading {
      margin-bottom: 4.45rem;
      max-width: 100%;
    }
    .contactInfo__socialIconsList {
      li {
        & a {
          white-space: nowrap;
          overflow: hidden;
          font-size: 1.5rem;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

#heroSec {
    & .heroSec__greetText {
        color: $tertiary-grey-color;
        font-weight: $fw-bold;
        font-size: 1.9rem;
        line-height: 1.5em;
        letter-spacing: 0.39em;
        position: relative;

        // hand svg
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            bottom: 0;
            display: inline-block;
            width: 2.1rem;
            height: 2.2rem;
            background: url("/assets/hand-logo.svg") no-repeat center;
            left: -28px;
        }

        @media only screen and (max-width: $bp-allPhones) {
            & {
                padding-left: 2.1em;

                &::before {
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }

    & .heroSec__heading {
        color: $text-color;
        font-weight: $fw-semi-bold;
        font-size: 6rem;
        line-height: 1.5em;
    }

    & .heroSec__subheading {
        max-width: 69ch;
        margin-bottom: 2.2222em;
    }

    & .heroSec__ctaBtn {
        font-size: 2.2rem;
        line-height: 1.63em;
        padding: 0.18182em 1.4545em;
        font-weight: $fw-medium;
        border-radius: 5px;
    }
}

// ---- Breakpoint  | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    #heroSec {
        & .heroSec__mobileFlexWrapper {
            display: flex;
            flex-flow: column nowrap;
            gap: 0.9rem 0;
            margin-bottom: 4rem;

            & > * {
                margin-bottom: 0;
            }
            .heroSec__greetText {
                font-size: 1.3rem;
            }
            .heroSec__heading {
                font-size: 3.2rem;
            }
        }
        & .heroSec__ctaBtn {
            font-size: 1.8rem;
            padding: 0.8rem 2rem;
        }
    }
}

@import "./projectCard";

#projects {
    padding-top: 6.4rem;
    margin-bottom: 25rem;

    .projects__mainHeading {
        margin-bottom: 5.1rem;
    }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    #projects {
        margin-bottom: 0;
        padding: 8rem 0 20rem;
    }
}

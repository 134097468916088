#logo {
    width: 73px;
    height: 52px;
}

.navbar__container-fluid {
    position: relative;
    margin: {
        top: 1.915rem;
        bottom: 12.935rem;
    }

    .navbar__container {
        @include flex-between();
        flex-flow: row nowrap;

        .navbar__items-container {
            @include flex-between();
            flex-flow: row nowrap;
            gap: 0;
            gap: 0 4rem;
            position: relative;
            z-index: 500;

            .navbar__nav-item {
                font-weight: $fw-regular;
                text-transform: capitalize;
                font-size: 2rem;
                padding: 0.45em 1.7em;

                &:any-link {
                    color: black;
                }
            }

            @media only screen and (min-width: 768.1px) {
                .navbar__nav-item {
                    --beforeVisiblity: hidden;
                    position: relative;

                    &:hover {
                        --beforeVisiblity: visible;
                    }

                    &::before {
                        content: "";
                        visibility: var(--beforeVisiblity);
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 60%;
                        transform: translateX(35%);
                        display: inline-block;
                        height: 0.1em;
                        background-color: black;
                        border-radius: 10%;
                    }
                }
            }
        }
    }
}

// ---- Breakpoint | below 768.1px ------
@media only screen and (max-width: 768.1px) {
    .mobileNavbar__items-container {
        padding-top: 4rem;
        transition: 0.4s left 0s ease;
        position: fixed;
        top: 0;
        right: 0;
        left: 100%;
        min-height: 100vh;
        background: rgb(49, 49, 49) url("./../assets/menuNavBar-liquid-cheese.svg") no-repeat center bottom;
        background-size: cover;
        z-index: $mobile-navMenu-index;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem 0;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;

        .mobileNavbar__nav-item {
            text-transform: capitalize;
            font-size: clamp(12px, 5.5vw, 25px);
            font-weight: $fw-medium;
            letter-spacing: 1px;
            text-align: left;
            background-color: rgb(58, 58, 58);
            padding: 0.9em 0;
            padding-left: 1.6em;

            &:first-child {
                margin-top: 10rem;
            }

            &:any-link {
                color: $white-color;
            }
        }
    }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    #logo {
        width: 4.8rem;
        height: 3.6rem;
    }
}

// Poppins font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// colors
$primary-pink-color: #ce3461;
$primary-darkPink-color: #d03e69;
// $secondary-whitesmoke-color: #f9f9f9;
$secondary-whitesmoke-color: #fafafa;
$tertiary-grey-color: #6c6c6c;
$white-color: #ffffff;
// $light-white-text-color: #ece2e2;
$text-color: #000000;
$light-text-color: #4f4f4f;

// z-indexes
$highest-index: 1500;
$mobile-navMenu-index: 1000;
$equal-index: 500;

// font weights
$fw-thin: 100;
$fw-xlight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-xbold: 800;
$fw-heavy: 900;

html {
    font-size: 62.5%; // 1rem = 10px
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif !important;
}

::selection {
    color: $primary-pink-color;
    background: pink;
}

#root {
    width: 100%;
    overflow: hidden;
}

body {
    background-color: rgb(255, 255, 255) !important;

    // hero section curve
    &::before {
        content: "";
        border: 1px sold green;
        position: absolute;
        z-index: $equal-index;
        top: 0;
        right: 0;
        width: 57.086rem;
        height: 52.411rem;
        background: url("/assets/hero-sec-curve.svg") no-repeat;

        @media only screen and (max-width: 768.1px) {
            & {
                background-size: 27.4rem 25.2rem;
                background-position: right top;
            }
        }
    }
}

body,
* {
    font-family: inherit !important;
}

.asterisk {
    max-width: 7px !important;
    height: auto;
    margin-left: 5px;
}

ul {
    list-style: none;
}

p {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

button {
    border: none;
}

input {
    border-radius: 0;
}

a:any-link {
    text-decoration: none;
}

#scrollToTopBtn {
    display: inline-block;
    right: 33px;
    bottom: 24px;
    padding: 16.55px 13.64px;
    position: fixed;
    z-index: $equal-index;
    background-color: $primary-pink-color;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
}

// portfolio common component styles
.portfolioInputBox {
    display: flex;
    flex-flow: column nowrap;
    gap: 0;

    &,
    .portfolioInputBox__input {
        font-size: 1.8rem;
    }

    & * {
        line-height: 1.63em;
    }

    .portfolioInputBox__label {
        font-size: 0.888889em;
        color: $tertiary-grey-color;
        font-weight: $fw-semi-bold;
        text-transform: capitalize;
    }

    .portfolioInputBox__input {
        margin-top: 0.3333333em;
        background-color: transparent;
        color: $light-text-color;
        font-weight: $fw-regular;
        padding: 0 0.5555556em;
        border: 0;
        outline: none;
        border-bottom: 1px solid $tertiary-grey-color;
    }
}

.portfolioTextAreaBox {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0;

    &,
    .portfolioInputBox__input {
        font-size: 1.8rem;
    }

    & * {
        line-height: 1.63em;
    }

    .portfolioInputBox__label {
        font-size: 0.888889em;
        color: $tertiary-grey-color;
        font-weight: $fw-semi-bold;
        text-transform: capitalize;
    }

    .portfolioTextAreaBox__txtArea {
        margin-top: 0.3333333em;
        background-color: transparent;
        color: $light-text-color;
        font-weight: $fw-regular;
        padding: 0 0.5555556em;
        border: 0;
        outline: none;
        border-bottom: 1px solid $tertiary-grey-color;
        resize: none;
        min-height: 13rem;
    }
}

.portfolioText {
    color: $light-text-color;
    font-weight: $fw-regular;
    font-size: 1.8rem;
    line-height: 1.63em;
}

.portfolioMainHeading {
    color: $text-color;
    font-size: 3.1rem;
    line-height: 1.63em;
    text-transform: capitalize !important;
}

.portfolioProjectHeading {
    color: $primary-pink-color;
    font-size: 2.6rem;
    line-height: 1.63em;
    text-transform: capitalize;
    margin-bottom: 0.576923em;
}

// font-weight
.portfolioMainHeading,
.portfolioProjectHeading {
    font-weight: $fw-semi-bold;
}

// helper classes
.redBorder {
    border: 2px solid red;
}
.greenBorder {
    border: 2px solid green;
}

.__pink-text-color {
    color: $primary-pink-color;
}

.left-0 {
    left: 0;
}

.left-100 {
    left: 100%;
}

// helper mixins
@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// device breakpoints
$bp-allPhones: 576px;
$bp-abovePhones: calc(#{$bp-allPhones} + 0.5px);
$bp-redmiNote7Pro: 394px;

// ---- Breakpoint : above mobile screens ------
@media only screen and (min-width: $bp-abovePhones) {
    .container {
        padding: 0 !important;
    }
}

// ---- Breakpoint : below 768.1px ------
@media only screen and (max-width: 768.1px) {
    .portfolioHamburger {
        z-index: $highest-index;
    }
}

// ---- Breakpoint : all phones ------
@media only screen and (max-width: $bp-allPhones) {
    .container {
        padding: 0 1.6rem !important;
    }
    .portfolioText {
        font-size: 1.6rem;
        line-height: 1.63em;
    }
    .portfolioMainHeading {
        font-size: 2.6rem;
        line-height: 1.5em;
    }
    .portfolioMobileFlexColumnWrapper {
        display: flex;
        flex-flow: column nowrap;

        & > * {
            margin-bottom: 0;
        }
    }
}

// ---- Breakpoint | redmi note 7 pro ------
@media only screen and (max-width: $bp-redmiNote7Pro) {
    .portfolioText {
        font-size: 1.5rem;
        line-height: 1.8em;
    }
}

.projectSubDescriptionCard {
    background-color: $secondary-whitesmoke-color;
    margin-bottom: 5.4rem;
    border-radius: 8px;
    padding: 2.5rem 2rem;

    & > .card__contentWrapper {
        margin-bottom: 2.6rem;
    }

    & .projectSubDescriptionCard__subHeading,
    & .projectSubDescriptionCard__subText {
        font-size: 1.5rem;
        line-height: 1.164em;
    }
    & .projectSubDescriptionCard__subHeading {
        color: $text-color;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        font-weight: $fw-semi-bold;
        margin-right: 0.6666667em;
    }

    & .projectSubDescriptionCard__subText {
        color: $light-text-color;
    }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
    .projectSubDescriptionCard {
        padding: 2.5rem 1.5rem;
        margin-bottom: 4.8rem;

        & .card__contentWrapper {
            margin-bottom: 2.4rem;
        }

        .projectSubDescriptionCard__subText,
        .projectSubDescriptionCard__subHeading {
            font-size: 1.4rem;
            line-height: 1.5em;
        }
    }
}

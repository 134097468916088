#contactForm {
  width: 58%;
  align-self: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 4.9rem 0;
  overflow-x: hidden;

  .contactForm__flexWrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & > * {
      width: 45%;
    }
  }
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
  #contactForm {
    width: 100%;
    .contactForm__flexWrapper {
      flex-flow: column nowrap;
      gap: 4.9rem 0;
      align-items: flex-start;

      & * {
        width: 100%;
      }
    }

    .contactForm__sendBtnBox {
      font-size: 1.6rem;
      margin: 2.75em 0;
    }
  }
}

@import "contactInfo";
@import "contactForm";

#contact {
	margin-bottom: 25rem;

	.contact__meainHeading {
		text-align: center;
		margin-bottom: 0.48387em;
	}
	.contact__subHeading {
		text-align: center;
		color: $light-text-color;
		max-width: 55ch;
		margin: 0 auto 3.977222em auto;
	}
	.contact__outerContentFlexWrapper {
		width: 100%;
		max-width: 1300px;
		border-radius: 27px;
		padding: 2rem;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

		display: flex;
		flex-flow: row nowrap;
		gap: 0 9rem;
		gap: 0 6rem;
		justify-content: flex-start;
		align-items: stretch;
	}

	.contactForm__sendBtnBox {
		padding: 0.8rem 1.6rem;
		align-self: flex-start;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-flow: row nowrap;
		gap: 0;
		border-radius: 5px;

		& {
			text-decoration: none;
		}

		&:hover {
			cursor: pointer;
		}

		.contactForm__btnMsg {
			font-size: 1.8rem;
			font-weight: $fw-semi-bold;
			line-height: 1.63em;
			color: $white-color;
			margin-right: 0.5555556em;
		}

		.contactForm__btnIcon {
			width: 24px;
			height: 24px;
			margin-bottom: 2px;
		}
	}
}

// ---- Breakpoint | all phones ------
@media only screen and (max-width: $bp-allPhones) {
	#contact {
		.contact__meainHeading {
			margin-bottom: 0.3461538em;
		}
		.contact__subHeading {
			max-width: 35ch;
		}
		.contact__outerContentFlexWrapper {
			max-width: 100%;
			flex-flow: column wrap;
			gap: 7.5rem 0;
			justify-content: flex-start;
			align-items: stretch;
		}
	}
}
